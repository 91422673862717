export default {
   SET_PARTNER_COMPANY_PAGINATION_INFO: (state, info) => {
      state.partnerCompanies.pagination.totalPages = info.totalPages
      state.partnerCompanies.pagination.tokenPages = info.tokenPages
   },

   SET_PARTNER_COMPANIES: (state, partnerCompanies) => {
      state.partnerCompanies.data = partnerCompanies.data;
   },

   SET_ALL_PARTNER_COMPANIES_BENEFITS_RELATION: (state, partnerCompaniesRelation) => {
      state.partnerCompanies.allPartnerCompaniesBenefitsRelation = partnerCompaniesRelation;
   },

   SET_PARTNER_COMPANY_CURRENT_PAGE: (state, currentPage) => {
      state.partnerCompanies.pagination.currentPage = currentPage
   },

   SET_PARTNER_COMPANY_LOADING: (state, loading) => {
      state.partnerCompanies.loading = loading
   }
}
