import store from '@/store';

export const ALL_AUTH_ROUTES = ['dashboard', 'dashReports', 'profile', 'articles', 'articlesCreate', 'articlesEdit',
   'categories', 'categoriesCreate', 'categoriesEdit', 'users', 'clients', 'usersCreate', 'usersEdit', 'coupons', 'couponsCreate',
   'couponsEdit'];

export const ADMIN_ALLOWED_ROUTES = ALL_AUTH_ROUTES;
export const EMPLOYEE_ALLOWED_ROUTES = ['articles', 'articlesCreate', 'articlesEdit', 'categories', 'categoriesCreate',
   'categoriesEdit', 'profile'];
export const PARTNER_ALLOWED_ROUTES = ['dashReports', 'dashboard', 'profile'];
export const CLIENT_ALLOWED_ROUTES = ['signature', 'profile'];

export const havePermission = (route) => {
   let allowedRoutes = [];

   const userProfile = store.state.auth.user.data.profile;
   switch (userProfile) {
      case 'admin':
         return true;
      case 'employee':
         allowedRoutes = EMPLOYEE_ALLOWED_ROUTES;
         break;
      case 'client':
         allowedRoutes = CLIENT_ALLOWED_ROUTES;
         break;
      case 'partner':
         allowedRoutes = PARTNER_ALLOWED_ROUTES;
   }

   const result = allowedRoutes.find((item) => item === route)
   return  result !== undefined;
}
