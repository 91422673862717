const Articles = () => import('../views/Articles.vue')
const ArticlesForm = () => import('../views/ArticlesForm.vue')

export default [
   {
      path: 'articles',
      name: 'articles',
      component: Articles
   },
   {
      path: 'articles/create',
      name: 'articlesCreate',
      component: ArticlesForm
   },
   {
      path: 'articles/:uuid',
      name: 'articlesEdit',
      component: ArticlesForm
   }
]
