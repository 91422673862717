const Benefits = () => import('../views/Benefits.vue')
const BenefitsForm = () => import('../views/BenefitsForm.vue')

export default [
   {
      path: 'benefits',
      name: 'benefits',
      component: Benefits
   },
   {
      path: 'benefits/create',
      name: 'benefitsCreate',
      component: BenefitsForm
   },
   {
      path: 'benefits/:uuid',
      name: 'benefitsEdit',
      component: BenefitsForm
   }
]
