const Questions = () => import("../views/Questions.vue");
const ReplyQuestion = () => import("../views/ReplyQuestion.vue");

export default [
   {
      path: "questions",
      name: "questions",
      component: Questions,
   },
   {
      path: "questions/:uuid",
      name: "replyQuestion",
      component: ReplyQuestion,
   },
];
