import vuex from 'vuex'
import auth from '@/modules/auth/store'
import article from '@/modules/dashboard/modules/article/store'
import benefit from '@/modules/dashboard/modules/benefit/store'
import branch from '@/modules/dashboard/modules/branch/store'
import category from '@/modules/dashboard/modules/category/store'
import coupon from '@/modules/dashboard/modules/coupon/store'
import partnerCompany from '@/modules/dashboard/modules/partnerCompany/store'
import question from '@/modules/dashboard/modules/question/store'
import report from '@/modules/dashboard/store'
import user from '@/modules/dashboard/modules/user/store'

export default new vuex.Store({
   modules: {
      auth,
      article,
      benefit,
      branch,
      category,
      partnerCompany,
      coupon,
      question,
      report,
      user
   }
})
