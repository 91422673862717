import { createRouter, createWebHistory } from 'vue-router'
import AuthRoutes from '@/modules/auth/router'
import DashboardRoutes from '@/modules/dashboard/router'
import Website from '@/modules/website/router'
import store from '@/store'
import { AUTH_TOKEN } from '@/helpers'
import { havePermission, EMPLOYEE_ALLOWED_ROUTES, PARTNER_ALLOWED_ROUTES, CLIENT_ALLOWED_ROUTES } from '@/utils/roles'
import jwt from 'jsonwebtoken'

const NotFound = () => import('@/views/NotFound.vue')

const routes = [
   { path: '', redirect: '/login'},
   ...AuthRoutes,
   ...DashboardRoutes,
   ...Website,
   { path: '/:pathMatch(.*)*', name: 'notFound', component: NotFound }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach(async (to, from, next) => {
   if (to.matched.some(route => route.meta.requiresAuth)) {
      const token = jwt.decode(localStorage.getItem(AUTH_TOKEN));

      const loginRoute = {
         path: '/login',
         query: { redirect: to.fullPath }
      }

      if (token) {
         const now = Date.now();

         try {
            if( now >= token.exp * 1000){
               next(loginRoute);
               setTimeout(() => {
                  store.commit('LOGOUT');
                  window.location.reload();
               }, 200)
               return;
            }

            if (!havePermission(to.name)){

               let routeToRedirect = { name: '' };

               const userProfile = store.state.auth.user.data.profile;
               switch (userProfile) {
                  case 'employee':
                     routeToRedirect.name = EMPLOYEE_ALLOWED_ROUTES[0]
                     break;
                  case 'client':
                     routeToRedirect.name = CLIENT_ALLOWED_ROUTES[0]
                     break;
                  case 'partner':
                     routeToRedirect.name = PARTNER_ALLOWED_ROUTES[0]
               }

               return next(routeToRedirect);
            }

            return next();
         } catch (e) {
            console.log('Auth login error:', e)
            return next(loginRoute);
         }
      }
      return next(loginRoute);
   }
   next();
})

export default router
