<template>
   <!-- Page content -->
   <router-view/>
   <!-- /.page content -->
</template>

<script>
export default {
   name: 'Default',
}
</script>
