import partnerCompanyService from "../services/partner-company-service";

export default {
   getAllPaginatedPartnerCompanies: async ({commit}, { pageSize = 10, pageToken = ''} = {}) => {
      commit('SET_PARTNER_COMPANY_LOADING', true)

      try {
         const response = await partnerCompanyService.getAllPaginated(pageSize, pageToken)
         commit('SET_PARTNER_COMPANIES', response.data)

      } catch (e) {
         console.log('Error getPartnerCompanies: ', e)
      } finally {
         commit('SET_PARTNER_COMPANY_LOADING', false)
      }
   },

   getInfoPaginationPartnerCompanies: async ({commit}, pageSize = 10) => {
      const { data: paginationInfo } = await partnerCompanyService.getPaginationInfo(pageSize);
      commit('SET_PARTNER_COMPANY_PAGINATION_INFO', { totalPages: paginationInfo.totalPages, tokenPages: paginationInfo.tokens })
   },

   getAllPartnerCompaniesBenefitsRelation: async ({commit}) => {
      const partnerCompaniesRelation = await partnerCompanyService.getAllPartnerCompaniesBenefitsRelation();
      commit('SET_ALL_PARTNER_COMPANIES_BENEFITS_RELATION', partnerCompaniesRelation);
   },

   searchPartnerCompanies: async ({commit}, name) => {
      commit('SET_PARTNER_COMPANY_LOADING', true)
      try {
         const results = await partnerCompanyService.search(name);
         let response = {
            data: results
         };

         commit('SET_PARTNER_COMPANIES', response)
         return response
      } finally {
         commit('SET_PARTNER_COMPANY_LOADING', false)
      }
   }
}
