const Coupons = () => import('../views/Coupons.vue')
const CouponsForm = () => import('../views/CouponsForm.vue')

export default [
   {
      path: 'coupons',
      name: 'coupons',
      component: Coupons
   },
   {
      path: 'coupons/create',
      name: 'couponsCreate',
      component: CouponsForm
   },
   {
      path: 'coupons/:uuid',
      name: 'couponsEdit',
      component: CouponsForm
   }
]
