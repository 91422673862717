export default {
   allCategories: [],
   categories: {
      loading: false,
      data: [],
      pagination: {
         tokenPages: [],
         totalPages: 0,
         currentPage: 1,
      }
   },
   currentCategory:{
      loading: false,
      data: {}
   }
}
