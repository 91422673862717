const Users = () => import('../views/Users.vue')
const Clients = () => import('../views/Clients.vue')
const UsersForm = () => import('../views/UsersForm.vue')

export default [
   {
      path: 'users',
      name: 'users',
      component: Users
   },
   {
      path: 'clients',
      name: 'clients',
      component: Clients
   },
   {
      path: 'users/create',
      name: 'usersCreate',
      component: UsersForm
   },
   {
      path: 'users/:email',
      name: 'usersEdit',
      component: UsersForm
   }
]
