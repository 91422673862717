export default {
   clients: {
      data: [],
      pagination: {
         tokenPages: [],
         totalPages: 0,
         currentPage: 1,
      }
   },
   users: {
      data: [],
      pagination: {
         tokenPages: [],
         totalPages: 0,
         currentPage: 1,
      }
   }
}
