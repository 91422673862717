import couponService from "../../../services/coupon-service";

export default {
   getAllPaginatedCoupons: async ({commit}, { pageSize = 10, pageToken = ''} = {}) => {
      commit('SET_COUPON_LOADING', true)

      try {
         const response = await couponService.getAllPaginated(pageSize, pageToken)
         commit('SET_COUPONS', response.data)

      } catch (e) {
         console.log('Error getCoupons: ', e)
      } finally {
         commit('SET_COUPON_LOADING', false)
      }
   },

   getInfoPaginationCoupons: async ({commit}, pageSize = 10) => {
      const { data: paginationInfo } = await couponService.getPaginationInfo(pageSize);
      commit('SET_COUPON_PAGINATION_INFO', { totalPages: paginationInfo.totalPages, tokenPages: paginationInfo.tokens })
   },

   searchCoupons: async ({commit}, name) => {
      commit('SET_COUPON_LOADING', true)
      try {
         const results = await couponService.search(name);
         let response = {
            data: results
         };

         commit('SET_COUPONS', response)
         return response
      } finally {
         commit('SET_COUPON_LOADING', false)
      }
   }
}
