export default {
   SET_CLIENT_PAGINATION_INFO: (state, info) => {
      state.clients.pagination.totalPages = info.totalPages
      state.clients.pagination.tokenPages = info.tokenPages
   },

   SET_CLIENT_DATA: (state, data) => {
      state.clients.data = data
   },

   SET_CLIENT_CURRENT_PAGE: (state, currentPage) => {
      state.clients.pagination.currentPage = currentPage
   },

   SET_USER_PAGINATION_INFO: (state, info) => {
      state.users.pagination.totalPages = info.totalPages
      state.users.pagination.tokenPages = info.tokenPages
   },

   SET_USER_DATA: (state, data) => {
      state.users.data = data
   },

   SET_USER_CURRENT_PAGE: (state, currentPage) => {
      state.users.pagination.currentPage = currentPage
   }
}
