import axiosClient from '@/axios'
import {db} from "../../../../../plugins/firebase";

const routeAuthenticated = '/auth/benefits';
const benefitsRef = db.collection('benefits');

const getAll = async () => {
   const benefits = await benefitsRef.get();

   const results = [];
   benefits.forEach((doc) => {
      let item = doc.data();
      results.push(item);
   });

   return results;
}

const getAllPaginated = async (pageSize = 10, pageToken = '') => {
   return await axiosClient.get(`${routeAuthenticated}/get-all-paginated?pageSize=${pageSize}&pageToken=${pageToken}`);
}

const getPaginationInfo = async (pageSize) => {
   return await axiosClient.get(`${routeAuthenticated}/get-pagination-info?pageSize=${pageSize}`)
}

const get = async uuid => {
   return await axiosClient.get(`${routeAuthenticated}/get/${uuid}`);
}

const save = async (type, benefit) => {
   if (type === 'create')
      return await axiosClient.post(`${routeAuthenticated}/create`, benefit);
   else
      return await axiosClient.put(`${routeAuthenticated}/edit/${benefit.uuid}`, benefit);
}

const search = async (name) => {
   const benefits = await benefitsRef.get();

   const results = [];
   benefits.forEach((doc) => {
      let item = doc.data();

      if (item.name) {
         const benefitNormalized = item.name.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase();
         const searchNormalized = name.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase();

         if (benefitNormalized.includes(searchNormalized))
            results.push(item);
      }
   });

   return results;
}

const destroy = async uuid => {
   return await axiosClient.delete(`${routeAuthenticated}/delete/${uuid}`);
}

export default {
   getAll,
   getAllPaginated,
   get,
   save,
   search,
   destroy,
   getPaginationInfo
}
