const Categories = () => import('../views/Categories.vue')
const CategoriesForm = () => import('../views/CategoriesForm.vue')

export default [
   {
      path: 'categories',
      name: 'categories',
      component: Categories
   },
   {
      path: 'categories/create',
      name: 'categoriesCreate',
      component: CategoriesForm
   },
   {
      path: 'categories/:uuid',
      name: 'categoriesEdit',
      component: CategoriesForm
   }
]
