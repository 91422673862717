const PartnerCompanies = () => import('../views/PartnerCompanies.vue')
const PartnerCompaniesForm = () => import('../views/PartnerCompaniesForm.vue')

export default [
   {
      path: 'partner-companies',
      name: 'partnerCompanies',
      component: PartnerCompanies
   },
   {
      path: 'partner-companies/create',
      name: 'partnerCompaniesCreate',
      component: PartnerCompaniesForm
   },
   {
      path: 'partner-companies/:uuid',
      name: 'partnerCompaniesEdit',
      component: PartnerCompaniesForm
   }
]
