import categoryService from "../services/category-service";

export default {
   deleteSelectedCategory: async ({commit}, uuid) => {
      const response = await categoryService.destroy(uuid)
      commit('DELETE_CATEGORY', uuid)
      return response
   },

   getCategory: async ({commit}, uuid) => {
      commit('SET_CURRENT_CATEGORY_LOADING', true)
      try {
         const response = await categoryService.get(uuid)
         commit('SET_CURRENT_CATEGORY', response.data)
         return response
      } catch (e) {
         const {status} = e.response
         console.log(status)
         console.log('Error getCategory: ', e)
      } finally {
         commit('SET_CURRENT_CATEGORY_LOADING', false)
      }
   },

   getAllPaginatedCategories: async ({commit}, { pageSize = 10, pageToken = ''} = {}) => {
      commit('SET_CATEGORIES_LOADING', true)

      try {
         const response = await categoryService.getAllPaginated(pageSize, pageToken)
         commit('SET_CATEGORIES', response.data)

      } catch (e) {
         console.log('Error getCategories: ', e)
      } finally {
         commit('SET_CATEGORIES_LOADING', false)
      }
   },

   getInfoPaginationCategories: async ({commit}, pageSize = 10) => {
      const { data: paginationInfo } = await categoryService.getPaginationInfo(pageSize)
      commit('SET_CATEGORY_PAGINATION_INFO', { totalPages: paginationInfo.totalPages, tokenPages: paginationInfo.tokens })
   },

   getAllCategories: async ({commit}) => {
      try {
         const response = await categoryService.index()
         commit('SET_ALL_CATEGORIES', response)
         return response
      } catch (e) {}
   },

   saveCategory: async ({commit}, { type, category }) => {
      if (type === 'create')
         commit('SET_IN_ALL_CATEGORIES', category)

      const response = await categoryService.save(type, category)
      commit('SET_CURRENT_CATEGORY', response.data)
      return response
   },

   searchCategories: async ({commit}, name) => {
      commit('SET_CATEGORIES_LOADING', true)
      try {
         const results = await categoryService.search(name);
         let response = {
            data: results
         };

         commit('SET_CATEGORIES', response)
         return response
      } finally {
         commit('SET_CATEGORIES_LOADING', false)
      }
   },
}
