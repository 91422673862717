import authService from '@/modules/auth/service/auth-service'
import firebase from "../../../plugins/firebase"
import userService from "../../dashboard/modules/user/services/user-service";

export default {
   async register ({}, user) {
      const response = await authService.register(user)
      return response
   },

   async login ({ commit }, { email, pwd }) {

      await firebase
         .auth()
         .signInWithEmailAndPassword(email, pwd);

      const result = await firebase.auth().currentUser.getIdTokenResult();

      const userProfile = result.claims.hasOwnProperty('role') ? result.claims.role : 'client';

      if (userProfile !== 'client') {
         if (!result.claims.isActive)
            return false;
      }

      const user = {
         userId: result.claims.user_id,
         email: result.claims.email,
         name: result.claims.name,
         profile: userProfile,
         token: result.token
      }
      commit('SET_USER', user);

      return true;
   },

   async passwordRecovery ({}, email) {
      const { data: userExists } = await userService.alreadyRegistered(email);

      if (!userExists)
         return false;

      await firebase
         .auth()
         .sendPasswordResetEmail(email);

      return true;
   },

   async logout ({ commit }) {
      commit('LOGOUT')
   }
}
