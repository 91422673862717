export default {
   SET_ALL_CATEGORIES: (state, allCategories) => {
      state.allCategories = allCategories
   },

   SET_IN_ALL_CATEGORIES: (state, payload) => {
      state.allCategories = [...state.allCategories, payload]
   },

   SET_CATEGORIES: (state, categories) => {
      state.categories.data = categories.data;
   },

   SET_CURRENT_CATEGORY: (state, category) => {
      state.currentCategory.data = category
   },

   DELETE_CATEGORY (state, uuid) {
      state.categories.data = state.categories.data.filter((category) => {
         return category.uuid !== uuid
      })
   },

   SET_CATEGORIES_LOADING: (state, loading) => {
      state.categories.loading = loading
   },

   SET_CURRENT_CATEGORY_LOADING: (state, status) => {
      state.currentCategory.loading = status
   },

   SET_NEW_UUID_IN_CURRENT_CATEGORY: (state, uuid) => {
      state.currentCategory.data.uuid = uuid;
   },

   SET_SUBCATEGORIES_IN_CURRENT_CATEGORY: (state, subcategory) => {
      state.currentCategory.data.subcategories.push(subcategory);
   },

   RESET_CURRENT_CATEGORY: (state) => {
      state.currentCategory.data = { uuid: null, name: '', subcategories: [] };
   },

   RESET_SUBCATEGORIES_IN_CURRENT_CATEGORY: (state) => {
      state.currentCategory.data.subcategories = [];
   },

   SET_CATEGORY_PAGINATION_INFO: (state, info) => {
      state.categories.pagination.totalPages = info.totalPages
      state.categories.pagination.tokenPages = info.tokenPages
   },

   SET_CATEGORY_CURRENT_PAGE: (state, currentPage) => {
      state.categories.pagination.currentPage = currentPage
   }
}
