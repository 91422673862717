export default {
   questions: {
      loading: false,
      data: [],
      pagination: {
         tokenPages: [],
         totalPages: 0,
         currentPage: 1,
      }
   },
   currentQuestion: {
      loading: false,
      data: {},
   }
};
