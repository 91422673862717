import BenefitService from "../services/benefit-service";

export default {
   getAllBenefits: async ({commit}) => {
      try {
         const response = await BenefitService.getAll();
         commit('SET_ALL_BENEFITS', response)

      } catch (e) {
         console.log('Error getAllBenefits: ', e)
      } finally {
      }
   },

   getAllPaginatedBenefits: async ({commit}, { pageSize = 10, pageToken = ''} = {}) => {
      commit('SET_BENEFIT_LOADING', true)

      try {
         const response = await BenefitService.getAllPaginated(pageSize, pageToken)
         commit('SET_BENEFITS', response.data)

      } catch (e) {
         console.log('Error getBenefits: ', e)
      } finally {
         commit('SET_BENEFIT_LOADING', false)
      }
   },

   getInfoPaginationBenefits: async ({commit}, pageSize = 10) => {
      const { data: paginationInfo } = await BenefitService.getPaginationInfo(pageSize);
      commit('SET_BENEFIT_PAGINATION_INFO', { totalPages: paginationInfo.totalPages, tokenPages: paginationInfo.tokens })
   },

   searchBenefits: async ({commit}, name) => {
      commit('SET_BENEFIT_LOADING', true)
      try {
         const results = await BenefitService.search(name);
         let response = {
            data: results
         };

         commit('SET_BENEFITS', response)
         return response
      } finally {
         commit('SET_BENEFIT_LOADING', false)
      }
   }
}
