const Login = () => import('./../views/Login.vue')
const Register = () => import('./../views/Register.vue')
const ForgotPassword = () => import('./../views/ForgotPassword.vue')

export default [
   {
      path: '',
      component: () => import('@/templates/Auth'),
      children: [
         {
            path: '/login',
            name: 'login',
            component: Login
         },
         {
            path: '/register',
            name: 'register',
            component: Register
         },
         {
            path: '/forgot-password',
            name: 'forgotPassword',
            component: ForgotPassword
         }
      ]
   }
]
