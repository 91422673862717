import { createApp } from 'vue'
import DefaultTemplate from './templates/Default'
import router from './router'
import vuex from './store'
import Toast from 'vue-toastification'
import 'vue-toastification/dist/index.css'
import { createI18n } from 'vue-i18n'
import pt_BR from './i18n/locales/pt_BR.json'
import VueMask from '@devindex/vue-mask';

const messages = { pt_BR: pt_BR }
const i18n = createI18n({
   locale: 'pt_BR',
   fallbackLocale: 'pt_BR',
   messages,
})

let app = createApp(DefaultTemplate)
app.use(router)
   .use(vuex)
   .use(Toast)
   .use(i18n)
   .use(VueMask)

app.mount('#app')
