export default {
   SET_COUPON_PAGINATION_INFO: (state, info) => {
      state.coupons.pagination.totalPages = info.totalPages
      state.coupons.pagination.tokenPages = info.tokenPages
   },

   SET_COUPONS: (state, coupons) => {
      state.coupons.data = coupons.data;
   },

   SET_COUPON_CURRENT_PAGE: (state, currentPage) => {
      state.coupons.pagination.currentPage = currentPage
   },

   SET_COUPON_LOADING: (state, loading) => {
      state.coupons.loading = loading
   }
}
