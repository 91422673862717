const Payment = () => import('./../view/Payment.vue')

export default [
   {
      path: '',
      component: () => import('@/templates/Default'),
      children: [
         {
            path: '/payment',
            name: 'payment',
            component: Payment
         }
      ]
   }
]
