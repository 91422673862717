import axiosClient from '@/axios'
import { db } from "../../../../../plugins/firebase";

const routeAuthenticated = '/auth/categories'
const categoriesRef = db.collection('categories');

const index = async () => {
   const categories = await categoriesRef.get();

   const allCategories = [];
   categories.forEach((doc) => {
      let item = doc.data();
      allCategories.push(item);
   });

   return allCategories;
}

const getPaginationInfo = async (pageSize) => {
   return await axiosClient.get(`${routeAuthenticated}/get-pagination-info?pageSize=${pageSize}`)
}

const getAllPaginated = async (pageSize = 10, pageToken = '') => {
   return await axiosClient.get(`${routeAuthenticated}/get-all-paginated?pageSize=${pageSize}&pageToken=${pageToken}`);
}

const get = async uuid => {
   return await axiosClient.get(`${routeAuthenticated}/get/${uuid}`);
}

const save = async (type, category) => {
   if (type === 'create')
      return await axiosClient.post(`${routeAuthenticated}/create`, category);
   else
      return await axiosClient.put(`${routeAuthenticated}/edit/${category.uuid}`, category);
}

const search = async (name) => {
   const categories = await categoriesRef.get();

   const results = [];
   categories.forEach((doc) => {
      let item = doc.data();

      if (item.name) {
         const categoryNormalized = item.name.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase();
         const searchNormalized = name.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase();

         if (categoryNormalized.includes(searchNormalized))
            results.push(item);
      }
   });

   return results;
}

const destroy = async uuid => {
   return await axiosClient.delete(`${routeAuthenticated}/delete/${uuid}`);
}

export default {
   index,
   getAllPaginated,
   get,
   save,
   destroy,
   getPaginationInfo,
   search,
}
