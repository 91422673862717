import userService from "../services/user-service";

export default {
   getAllPaginatedUsers: async ({commit}, {type, pageSize = 10} = {}) => {
      const response = await userService.getAll(type, pageSize);

      if (type === 'client'){
         commit('SET_CLIENT_PAGINATION_INFO', { totalPages: response.totalPages, tokenPages: response.tokenPages });
         commit('SET_CLIENT_DATA', response.data);
      }
      else{
         commit('SET_USER_PAGINATION_INFO', { totalPages: response.totalPages, tokenPages: response.tokenPages });
         commit('SET_USER_DATA', response.data);
      }
   },

   searchUsers: async ({commit}, {type, name}) => {
      const response = await userService.search(type, name);

      if (type === 'client')
         commit('SET_CLIENT_DATA', response);
      else
         commit('SET_USER_DATA', response);
   }
}
